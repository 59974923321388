/*
 * @Date: 2023-03-28 12:19:06
 * @LastEditTime: 2023-09-08 13:54:17
 * @Description: 
 * @FilePath: \lsz-user-mp\src\api\invoice.js
 */
import { Http } from '../utils/http'

const PREFIX_URL = '/user/myAppointment'

// 开票相关接口
export class InvoiceModel extends Http {
  // 创建挂号的开票单据
  create(registerid) {
    return this.post(`${PREFIX_URL}/createInvoice`,  { registerid }, { isFormData:true })
  }
  // 退费挂号开票
  refundInvoice(registerid) {
    return this.post(`/user/myAppointment/refundInvoice`, { registerid }, { isFormData: true })
  }

  // 创建处方的开票单据
  createPrescriptionInvoice(cfddbm) {
    return this.post('/user/myPrescription/createInvoice',  { cfddbm }, { isFormData:true })
  }
  // 退费处方开票
  createPrescriptionRefundInvoice(cfddbm) {
    return this.post('/user/myPrescription/refundInvoice', { cfddbm }, { isFormData: true })
  }

  // 检验发票（正票）
  createJyInvoice(ddbm) {
    return this.post('/user/my_jy/createInvoice',  { ddbm }, { isFormData:true })
  }
  // 检验发票(红票)
  createJyRefundInvoice(ddbm) {
    return this.post('/user/my_jy/refundInvoice', { ddbm }, { isFormData: true })
  }

  // 查询发票信息 orderNumber: 订单号
  query(orderNumber, kplx) {
    return this.post(`${PREFIX_URL}/queryInvoiceByOrderNumber`, { orderNumber, kplx }, { isFormData:true })
  }

  /**
   * 发送邮件
   * @param email 邮箱地址
   * @param orderNumber 订单号
   * @returns {Promise | Promise<any>}
   */
  sendEmail(email, orderNumber, kplx) {
    return this.post(`${PREFIX_URL}/sendEmail`, { email, orderNumber, kplx }, { isFormData:true })
  }

  getPdfStream(orderNumber) {
    return this.get(`${PREFIX_URL}/queryInvoiceFile/${orderNumber}`, undefined, { headers: {responseType: 'blob'} })
  }
}

export const invocieModel = new InvoiceModel()
